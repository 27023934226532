.cupcakes-section {
    text-align: center;
    padding: 2rem;
    background-color: #f9f8f6; /* Soft off-white */
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    margin: 0.5rem auto;
  }
  
  .cupcakes-title {
    font-size: 2.5rem;
    color: #d2691e; /* Warm chocolatey color */
    font-family: 'Georgia', serif;
    margin-bottom: 0.5rem;
  }
  
  .cupcakes-description {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    font-family: 'Arial', sans-serif;
  }
  