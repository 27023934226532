/* Product.css */
.home-page {
    background-color: #FFF7E6;
    padding: 20px;
  }
  
  .home-page h1 {
    color: #5D4037;
  }
  
  .home-page p {
    color: #3E2723;
  }
  
  .product-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr)); /* Forces at least two items per row */
    gap: 10px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%; /* Ensures the list spans full container */
    margin-bottom: 100px;
    margin-top: 25px;
  }
  
  .product-list li {
    background-color: #f4f4f4; /* Styling to visualize */
    border: 1px solid #ddd; /* Optional styling */
    padding: 10px;
    text-align: center;
  }
  
  .product-list img {
    width: 100%;
    border-radius: 10px;
  }
  
  .product-list h3 {
    color: #5D4037;
  }
  
  .product-list p {
    color: #3E2723;
  }
  
  .product-list button {
    background-color: #FFC107;
    color: #3E2723;
    padding: 10px;
    border: none;
    cursor: pointer;
  }
  
  .product-list button {
    background-color: #FFC107;
    color: #3E2723;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
  }
  
  .product-list button:hover {
    background-color: #FFB300;
    color: #ffffff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
  
  .product-list button:focus {
    outline: none;
    border: 2px solid #FFB300;
    box-shadow: 0 0 10px rgba(255, 179, 0, 0.7);
  }
  
  .product-list button:active {
    background-color: #FF8F00;
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (min-width: 768px) {
    .product-list {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* More items per row for larger screens */
    }
  }