
/* Hamburger button */
.menu-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #5A3E36;
    cursor: pointer;
    position: fixed;
    top: 15px;
    right: 30px;
    z-index: 1000;
}

/* Sidebar menu */
.sidebar-menu {
    position: fixed;
    padding-right: 35px;
    padding-top: 10px;
    top: 70px;
    right: -290px;
    height: 400px;
    width: 180px;
    background-color: #e4cd4d;
    overflow-y: hidden; /* Allow scrolling if content overflows */
    color: #5A3E36;
    transition: transform 0.3s ease;
    z-index: 999;
}

.sidebar-menu.open {
    transform: translateX(-250px);
    /* transform: translateY(250px); */
}

.sidebar-menu.open {
    /* If the menu is open, the sidebar can be expanded */
    height: auto; /* Allow the content to push down */
    display: absolute;
}

/* Basic dropdown links */
.sidebar-menu a {
    color: #5A3E36;
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    display: block;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between links */
    padding: 20px;
}

.sidebar-menu a:hover {
    color: #D9663D; /* Highlighted orange on hover */
}


.logo {
    height: 90px;
    width: 90px;
    border-radius: 40px;
    margin: auto;
}