/* Dropdown container stays in flow */
.dropdown-container {
    width: 100%;
    margin: 0;
}

/* Header button with caret */
.dropdown-menu {
    width: 100%; /* Full width of the container */
    text-align: center; /* Center text */
    border: none;
    font-size: 1.0em;
    font-weight: bold;
    padding: 10px;
    color: #5A3E36; /* Dark brown */
    background-color: #e4cd4d;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; /* Centers both text and caret */
    gap: 5px; /* Space between text and caret */
    margin-top: 14px;
}

/* Jumbotron button styling */
.dropdown-menu.jumbotron-menu {
    width: 50%;
    height: 85px;
    background-color: #448d3b; /* Darker beige for emphasis */
    color: #ffffff; /* White text */
    font-size: 2.0em;
    font-weight: bold;
    padding: 10px 0px;
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    display: inline-flex; /* Inline-flex to prevent full-width */
}

/* Hover effect for jumbotron button */
.dropdown-menu.jumbotron-menu:hover {
    background-color: #559c39; /* Slightly darker beige */
    color: #ffffff;
}

/* Caret transition */
.caret {
    transition: transform 0.3s ease;
}

.caret.open {
    transform: rotate(180deg);
}

/* Dropdown content container with sliding effect */
.dropdown-content {
    max-height: 0;
    overflow: hidden;
    background-color: #e2caad; /* Beige color */
    transition: max-height 0.3s ease;
}

/* Open state for dropdown with expanded height */
.dropdown-content.show {
    max-height: 200px; /* Adjust this value based on content */
    overflow: visible;
}

/* Dropdown links */
.dropdown-content .cake-link, .dropdown-content .cupcake-link, .dropdown-content .cookie-link {
    color: #5A3E36;
    padding: 10px 15px;
    margin: 0px;
    text-decoration: none;
    font-weight: bold;
    display: block;
    transition: background-color 0.3s;
    font-size: 18px;
}

.dropdown-content .cake-link:hover, .dropdown-content .cupcake-link:hover, .dropdown-content .cookie-link:hover {
    background-color: #D9663D; /* Highlighted orange on hover */
    color: #fff;
}

.sidebar-menu .dropdown-content .cake-link:hover, .sidebar-menu .dropdown-content .cupcake-link:hover, .sidebar-menu .dropdown-content .cookie-link:hover {
    background-color: #D9663D; /* Highlighted orange on hover */
    color: #fff;
}

.dropdown-container.jumbotron-menu {
    position: relative;
}

.dropdown-container.navbar-dropdownmenu  {
    position: relative;
}

.navbar-dropdownmenu {
    display: inline-block;
    margin-top: -34px;
    z-index: 1000;
    width: 100%
}

.jumbotron-menu {
    display: inline-block;
    z-index: 1000;
    width: 100%
}

.navbar-dropdownmenu + .dropdown-content {
    color: white;
}

.dropdown-menu.navbar-dropdownmenu {
    background-color: white;
}

.dropdown-container.jumbotron-menu, .dropdown-container.navbar-dropdownmenu {
    padding-top: 34px;
}

.dropdown-menu.jumbotron-menu {
    font-size: 28px;
} 

.dropdown-menu.navbar-dropdownmenu {
    min-width: 25%;
    min-height: 80px;
    font-size: 20px;
}

.jumbotron-menu .dropdown-content, .navbar-dropdownmenu .dropdown-content {
    color: white;
    position: absolute;
}

.navbar-dropdownmenu .dropdown-content, .jumbotron-menu .dropdown-content {
    width: 100%;
    left: 0px;
}

.jumbotron-menu .dropdown-content {
    width: 50%;
    position: relative;
    margin: auto;
}

.navbar-dropdownmenu .dropdown-content a {
    position: relative;
    bottom: 15px;
    background-color: #e2caad; /* Beige color */
    overflow: visible;
}

.jumbotron-menu .dropdown-content a {
    position: relative;
    overflow: visible;
    top: 0px;
    background-color: #e2caad; /* Beige color */
    width: 100%;
}

@media (min-width: 768px) {
    .menu2 {
        display: none;
    }

    /* Dropdown links */
    .jumbotron-menu .dropdown-content .cake-link, .jumbotron-menu .dropdown-content .cupcake-link, .jumbotron-menu .dropdown-content .cookie-link {
        color: #5A3E36;
        padding: 10px 15px;
        margin: 0px;
        text-decoration: none;
        font-weight: bold;
        display: block;
        transition: background-color 0.3s;
        font-size: 24px;
    }

    /* Dropdown links */
    .navbar-dropdownmenu .dropdown-content .cake-link, .navbar-dropdownmenu .dropdown-content .cupcake-link, .navbar-dropdownmenu .dropdown-content .cookie-link {
        color: #5A3E36;
        padding: 10px 15px;
        margin: 0px;
        text-decoration: none;
        font-weight: bold;
        display: block;
        transition: background-color 0.3s;
        font-size: 20px;
    }

    .dropdown-menu.navbar-dropdownmenu {
        font-size: 22px;
        padding-bottom: 0px;
    }

    .jumbotron-menu .dropdown-content {
        width: 50%;
        position: relative;
        margin: auto;
    }

    .dropdown-content .cake-link:hover, .dropdown-content .cupcake-link:hover, .dropdown-content .cookie-link:hover {
        background-color: #D9663D; /* Highlighted orange on hover */
        color: #fff;
    }
}

@media (min-width: 1024px) {
    .dropdown-menu {
        width: 100%; /* Full width of the container */
        text-align: center; /* Center text */
        border: none;
        font-size: 1.5em;
        font-weight: bold;
        padding: 10px;
        color: #5A3E36; /* Dark brown */
        background-color: #e4cd4d;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center; /* Centers both text and caret */
        gap: 5px; /* Space between text and caret */
        margin-top: 14px;
    }
    
    /* Jumbotron button styling */
    .dropdown-menu.jumbotron-menu {
        font-size: 2.5em;
        height: 150px;
        width: 25%;
    }
    .jumbotron-menu .dropdown-content {
        width: 25%;
    }

    .navbar-dropdownmenu {
        display: inline-block;
        margin-top: -34px;
        z-index: 1000;
        width: 100%;
        padding-bottom: 0px;
    }

    .dropdown-menu.navbar-dropdownmenu {
        font-size: 24px;
    }

    /* Dropdown links */
    .dropdown-content a {
        color: #5A3E36;
        padding: 10px 15px;
        margin: 0px;
        text-decoration: none;
        font-weight: bold;
        font-size: 1.5em;
        display: block;
        transition: background-color 0.3s;
    }

    /* Dropdown links */
    .navbar-dropdownmenu .dropdown-content .cake-link, .navbar-dropdownmenu .dropdown-content .cupcake-link, .navbar-dropdownmenu .dropdown-content .cookie-link {
        color: #5A3E36;
        padding: 10px 15px;
        margin: 0px;
        text-decoration: none;
        font-weight: bold;
        display: block;
        transition: background-color 0.3s;
        font-size: 24px;
    }

    .sidebar-menu .dropdown-content .cake-link:hover, .sidebar-menu .dropdown-content .cupcake-link:hover, .sidebar-menu .dropdown-content .cookie-link:hover {
        background-color: #D9663D; /* Highlighted orange on hover */
        color: #fff;
    }

    .dropdown-content .cake-link:hover, .dropdown-content .cupcake-link:hover, .dropdown-content .cookie-link:hover {
        background-color: #D9663D; /* Highlighted orange on hover */
        color: #fff;
    }
}

