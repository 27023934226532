.jumbotron {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content closer to the top */
  align-items: center;
  width: 100%;
  padding-top: 5vh;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); */
  background-color: #f0c289; /* Soft beige */
  color: #5A3E36; /* Warm, dark brown */
  text-align: center;
  box-sizing: border-box;
  height: 525px;
}

.jumbotron-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.jumbotron .jumbotron-image {
  width: 20%;
  height: auto;
  margin-right: -25px;
}

.jumbotron h1 {
  font-family: 'Pacifico', cursive;
  font-size: 7vw;
  margin-bottom: 10px;
  margin-top: 5px;
}

.jumbotron p {
  font-family: 'Open Sans', sans-serif;
  font-size: 3.7vw;
  margin-top: 5px;
}

.order-button {
  background-color: #D9663D; /* Soft orange */
  border: none;
  padding: 10px 20px;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 2.5vw;
  margin-top: 10px;
}

.order-button:hover {
  background-color: #B24A2F;
}

.dropdownmenu-container {
  position: relative;
}

@media (min-width: 768px) {
  
  .jumbotron {
    height: 600px;
  }
  .jumbotron-content {
    margin-top: 100px;
  }

  .jumbotron img {
    max-width: 10%;
    height: auto;
    margin-right: -25px;
  }

  .jumbotron h1 {
    font-family: 'Pacifico', cursive;
    font-size: 5vw;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  
  .jumbotron p {
    font-family: 'Open Sans', sans-serif;
    font-size: 3vw;
    margin-top: 5px;
  }
}

@media (min-width: 1024px) {
  .jumbotron {
    height: 800px;
  }

}