* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Includes padding and borders in the element's width */
}

img, video, iframe {
  max-width: 100%;
  height: auto;
}

.App {
  text-align: center;
  margin: 0;
  padding: 0;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%; /* Fit width */
  height: 100%; /* Ensure no excess height */
}

.App-link {
  color: #61dafb;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%; /* Ensure the root elements stretch fully */

}

html, body, #root, .App {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%;        /* Ensure the width fits exactly */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  /* .app {
    min-height: 100vh;
  } */

  .content {
    flex: 1;
  }
}
