 
  .cart-icon {
    font-size: 24px;
    text-align: center;
    position: relative;
    top: 5px; /* Moves the info box directly above the cart icon */  
  }
  
 
  
  